/* eslint-disable no-multi-str */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Input from './common/Input';
import { Badge, Form } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const AddPage = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [message, setMessage] = useState('');
    const [content, setContent] = useState('Enter Page Content...');
    const [menu, setMenu] = useState('');

    const fetchMenu = () => {
        coreContext.fetchMenu();
    }

    useEffect(fetchMenu, []);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    const onTitleChangedHandler = (e) => {
        setTitle(e.target.value);
    }

    const onSlugChangedHandler = (e) => {
        setSlug(e.target.value);
    }

    const onMessageChangedHandler = (e) => {
        setMessage(e.target.value);
    }

    const onMenuChangedHandler = (e) => {
        setMenu(e.target.value);
    }


    const handleEditorChange = (content, editor) => {
        setContent(content);
    }

    const onPageContentHandler = (data) => {
        coreContext.addPageContent(title, message, content, slug, menu, authContext.itemId, authContext.userId);

    }


    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Add New Page</Badge></h3></div>
            <div className="card-body">

                <Form autoComplete='off' onSubmit={handleSubmit(onPageContentHandler)} noValidate>

                    <Input label='Menu' name='menu' required={true} register={register} errors={errors} elementType='select' value={menu} options={coreContext.menu} onChange={onMenuChangedHandler} />

                    <Input label='Title' value={title} name='title' minLength={2} maxLength={155} required={true} register={register} errors={errors} elementType='textarea' placeholder='Enter Title' onChange={onTitleChangedHandler} />

                    <Input label='Slug' value={slug} name='slug' minLength={2} maxLength={155} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Slug' onChange={onSlugChangedHandler} />

                    <Input label='Message' value={message} name='message' minLength={2} maxLength={2000} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Message' onChange={onMessageChangedHandler} />


                    <Form.Group>
                        <Editor
                            apiKey={'5k5x5ez6z06mvgu1fkuizuc0wa07bqni14qml1baljvj2ijp'}
                            value={content}
                            init={{
                                height: 500,
                                menubar: 'insert',
                                file_picker_types: 'file image media',
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor forecolor  | fontsize fontfamily lineheight | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent image | removeformat | code | help'
                            }}
                            onEditorChange={handleEditorChange}
                        />
                    </Form.Group>
                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                    <Input variant='danger' label={coreContext.message} elementType='label' />
                </Form>
            </div>
        </div>
    </div>
    )
}

export { AddPage }