/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import Axios from 'axios';
import { Eye } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import PMenu from './common/ProfileMenu';
import { Link } from 'react-router-dom';



const SahyogEventContributors = (props) => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const [itemEvents, setItemEvents] = useState([]);

    const fetchEvents = () => {

        coreContext.setMessage('');

        Axios.get('/jnv-sahyog-events-contributors', { params: { eventId: props.match.params.id } }).then(response => {
            //  console.log(response.data);
            const eids = [];
            const events = [];
            const evs = response.data.events;
            evs.forEach(event => {
                if (!eids.includes(event.id)) {
                    eids.push(event.id);
                    events.push(event);
                }
            });
            setItemEvents(events);

        }).catch(err => {


        });

    }



    useEffect(fetchEvents, []);

    const getTotalAmount = () => {
        let tam = 0;
        itemEvents.forEach(ie => {
            tam += parseInt(ie.amount);
        });

        return tam;

    }


    const deleteContribution = (id, eventId) => {

        Axios.post('/delete-contribution', { id, eventId}).then((response) => {
            // window.location.assign('/events');
            fetchEvents();

        }).catch(err => { });
    }

    const showDeleteWarning = (cl) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('This contribution record will be removed permanently, do you still want to delete ?')) {
            deleteContribution(cl, props.match.params.id);
        }
    }

    const renderEvents = () => {
        if (itemEvents.length > 0) {
            return itemEvents.map((se, index) => {

                let purl = 'https://'+se.paymentUrl;

                return <tr key={++index}>
                    <th>{index}</th>
                    <td>{se.item_name}</td>
                    <td>{new Date(se.createdAt).toDateString()}</td>
                    <td>{se.district}</td>
                    <td>{se.address}</td>
                    <td>{se.membership_start_date}</td>
                    <td>{se.amount}</td>
                    <td><a target="_blank" rel="noopener noreferrer" href={purl}><img style={{height: '50px'}} className='img-thumbnail' alt='receipt' src={purl}/></a></td>
                    <td><button className="btn btn-danger" onClick={() => showDeleteWarning(se.id)} href='#' title="Delete Contribution"><XSquare size="12" /></button></td>

                </tr>
            });
        }
        else {
            return (<tr>
                <th>No contributors currently !</th>
            </tr>)
        }
    }




    return (
        <div className='row'>
            <div className='col-md-12'>
                <div className='card card-body'>
                    <h5 className='card-title'>
                        <Link className="btn btn-link" to='/sahyog-events'>{'<<< Back'}</Link>
                    </h5>
                    <h5 className='card-title'>Event Contributors for - {props.match.params.name}</h5>
                    <table className="table table-sm table-bordered">

                        {itemEvents.length > 0 ? <thead><th></th>
                            <th>Name</th>
                            <th>Payment Date</th>
                            <th>District</th>
                            <th>Address</th>
                            <th>Joining</th>
                            <th>Amount (Rs.)</th> 
                            <th></th>
                            <th></th> 
                            </thead> : ''}
                        <tbody>
                            {renderEvents()}
                        </tbody>
                        {itemEvents.length > 0 ?
                        <tfoot>
                            <tr>
                                <th colspan='6'>Total</th>
                                <th>{getTotalAmount()}</th>
                            </tr>
                        </tfoot>
                        : ''}
                    </table>
                </div>
            </div>

        </div>
    );

}

export { SahyogEventContributors };