/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import PMenu from './common/ApplicantProfileMenu';
import ImageUploader from './common/UploadImage';


const JnvClient = (props) => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [name, setName] = useState('');
    const [address, setAddress] = useState();
    const [ayear, setAyear] = useState('');
    const [pyear, setPyear] = useState('');
    const [aclass, setAclass] = useState('');
    const [pclass, setPclass] = useState('');
    const [curl, setCurl] = useState('');
 

    const [aClassOptions] = useState(coreContext.admissionClassOptions);
    const [pClassOptions] = useState(coreContext.passOutClassOptions);
    const [image, setImage] = useState('');

    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);


    //useEffect(setClient(coreContext.client), [client]);

    const fetchJnv = () => {

        //this.props.match.params.id //accessing param
       // console.log('params', props.match);
       if (authContext.item.smst_designation === 'Admin')
        coreContext.fetchJnv(props.match.params.id);
       // console.log('jnv client',coreContext.client);
    }

    useEffect(fetchJnv, []);





    const populateJnv = () => {
          console.log(coreContext.jnv);
          if(coreContext.jnv){
        setName(coreContext.jnv.district);
        setAddress(coreContext.jnv.address);
        setAyear(coreContext.jnv.admission_year);
        setPyear(coreContext.jnv.passout_year);
        setAclass(coreContext.jnv.admission_class);
        setPclass(coreContext.jnv.passout_class);
        setCurl(coreContext.jnv.certificate_url);
        setImage(coreContext.jnv.certificate_url);
          } else setUploadButtonDisabled(true);
    }

    useEffect(populateJnv, [coreContext.jnv]);


    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        if (count > 1) { alert('Only one image should be uploaded for this section !'); return; }
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', authContext.itemId);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadRegisterNowImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    fetchJnv();
                }, 2000);

                setTimeout(() => {
                    history.push('/profile');
                }, 5000);
            };
        }
    }

    const renderImages = () => {
        if (image)
            return <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`http://${image}`} alt='Certificate / Marksheet' /></div>
    }

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    }
    );

    const letterText = () => {
        if(coreContext.client.smst_category == 1)
        return 'Upload X / XII Marksheet / Certificate';
        else
        return 'Joining Letter / Certified Letter from principal on Letter head';
    }

    const showFields = () => {
        if(coreContext.client.smst_category == 1)
            return <>
            <Input label='Admission Year' value={ayear} name='ayear' minLength={4} maxLength={4} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Admission Year eg. 2014' onChange={(e) => setAyear(e.target.value)} />

            <Input label='Admission Class' value={aclass} name='aclass' required={true} register={register} errors={errors} elementType='select' options={aClassOptions} onChange={(e) => setAclass(e.target.value)} />

            <Input label='Passout Year' value={pyear} name='pyear' minLength={4} maxLength={4} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Passout Year eg. 2014' onChange={(e) => setPyear(e.target.value)} />

            <Input label='Passout Class' name='pclass' value={pclass} required={true} register={register} errors={errors} elementType='select' options={pClassOptions} onChange={(e) => setPclass(e.target.value)} /> </>

            else return   <>
            <Input label='Joining Date in Jnv' value={ayear} name='ayear' minLength={10} maxLength={10} required={true} register={register} errors={errors} elementType='date' placeholder='Joining Date in Jnv' onChange={(e) => setAyear(e.target.value)} />

            <Input label='Joining Date (Current Jnv)' value={pyear} name='pyear' minLength={10} maxLength={10} required={true} register={register} errors={errors} elementType='date' placeholder='Joining Date in current Jnv' onChange={(e) => setPyear(e.target.value)} />
</>
        }

    

    const jnvUpdateHandler = (data) => {
        // console.log('here also');
        coreContext.updateJnv(authContext.itemId, name, address, ayear, aclass,pyear, pclass );

    }





    return (<div className='container'>
        <PMenu id={props.match.params.id} />
        <div className="row" >
            <div className="col-md-12">
                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">JNV Details</Badge></h3></div>
                    <div className="card-body">
                        <Form autoComplete='off' onSubmit={handleSubmit(jnvUpdateHandler)} noValidate>
                            <Input label='Jawahar Navodaya Vidyalaya District' value={name} name='name' minLength={2} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter JNV District Name' onChange={e => setName(e.target.value)} />

                            <Input label='JNV Address' value={address} name='address' minLength={5} maxLength={255} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter JVN Address' onChange={(e) => setAddress(e.target.value)} />

{showFields()}
                            <br />

                            {/* <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp;  */}
                            {coreContext.renderLoader()}
                            <Input variant='danger' label={coreContext.message} elementType='label' />
                        </Form>
                    </div>
                </div>

                <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success"> {letterText()} </Badge></h3></div>
            <div className="card-title mx-auto"></div>
         
            <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Your Marksheet / Certificate</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
            </div>

        </div> </div>);
}


export { JnvClient };