/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";


const ProfileMenu = (props) => {

  
    return (<div className='btn btn-group'>
    <Link className="btn btn-primary" to='/profile'>Personal Details</Link>
    <Link className="btn btn-primary ml-2" to='/jnv-details'>JNV Details</Link>
    <Link className="btn btn-primary ml-2" to='/jnv-health-details'>Health Details</Link>
    <Link className="btn btn-primary ml-2" to='/jnv-family-details'>Family Details</Link>
        </div>);

}

export default ProfileMenu;
