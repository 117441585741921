/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import Axios from 'axios';
import { Eye } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import PMenu from './common/ProfileMenu';
import { Link } from 'react-router-dom';



const SahyogEvents = (props) => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();



    const fetchEvents = () => {

        //this.props.match.params.id //accessing param
       // console.log('params', props.match);
       coreContext.fetchSahyogEvents(authContext.ownerId);
    }


 
    useEffect(fetchEvents, []);

    const renderCertificate = (certificate) => {
       
        if (certificate){
            if(certificate.includes('.pdf'))
            return <div className="col-md-6"> <a rel="noopener noreferrer" target="_blank" href={`https://${certificate}`}><img style={{ padding: 5 }} className="img-fluid" src="https://node-websites.siddhantait.com/images/pdfimage.png" alt='Certificate' /></a></div>
            else
            return <div className="col-md-6"> <img style={{ padding: 5 }} className="img-fluid" src={`https://${certificate}`} alt='Certificate' /></div>
        }
            return <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src='https://node-websites.siddhantait.com/images/file-not-found-icon-png.png' alt='Certificate' /></div>
    }


    const renderEvents = () => {
        if (coreContext.sahyogEvents.length > 0) {
            return coreContext.sahyogEvents.map((se, index) => {

                return <tr key={++index}>
                    <th>{index}</th>
                    <td>{se.item_name}</td>
                    <td>{se.status.toUpperCase()}</td>
                    <td>{se.district}</td>
                    <td>{se.address}</td>
                    <td>{se.membership_start_date}</td>
                    <td>{se.comments}</td>
                    <td>{renderCertificate(se.imageUrl)}</td>
                    <td><Link className="btn btn-link" to={`/sahyog-event-contributors/${se.id}/${se.item_name}`}>Contributors</Link></td>
                </tr>
            });
        }
        else {
            return ( <tr>
                <th>No active events currently !</th>
            </tr>)
        }
    }




return(
    <div className='row'>
    <div className='col-md-12'>
<div className='card card-body'>
   <h5 className='card-title'>Sahyog Events</h5>
   <table className="table table-sm table-bordered">
   
        {coreContext.sahyogEvents.length > 0 ?  <thead><th></th>
            <th>Name</th>
            <th>Status</th>
            <th>District</th>
            <th>Address</th>
            <th>Joining</th>
            <th>Details</th>  </thead> : ''}
   <tbody>
   {renderEvents()}
   </tbody>
   </table>
</div>
</div>
    
</div>
);

}

export { SahyogEvents };