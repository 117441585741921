/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useHistory } from 'react-router-dom';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import ImageUploader from './common/UploadImage';


const Client = (props) => {
    const history = useHistory();


    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const [name, setName] = useState('');
    const [contactName, setContactName] = useState('');
    const [tag, setTag] = useState('');
    const [loc, setLOC] = useState('');
    const [email, setEmail] = useState('');
    const [contact1, setContact1] = useState('');
    const [contact2, setContact2] = useState();
    const [contact, setContact] = useState();
    const [address, setAddress] = useState();
    const [description, setDescription] = useState();

    const [pincode, setPincode] = useState();

    const [localArea, setLocalArea] = useState('');

    const [stateId, setStateId] = useState('');
    const [jDate, setJDate] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [category, setCategory] = useState('');
    const [countryId, setCountryId] = useState('');
    const [erpBranchid, setErpBranchid] = useState('');
    const [isCollege, setIsCollege] = useState('no');
    const [hasImages, setHasImages] = useState(false);
    const [hideMenu, setHideMenu] = useState('no');
    const [hideFooter, setHideFooter] = useState('no');
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);

    const [status, setStatus] = useState('');
    const [siteUrl, setSiteURL] = useState('');
    const [nationality, setNationality] = useState('Indian');
    const [sCategory, setSCategory] = useState('');
    const [organization, setOrganization] = useState('');
    const [designation, setDesignation] = useState('');
    const [accomodation, setAccomodation] = useState('yes');
    const [presenting, setPresenting] = useState('yes');
    const [role, setRole] = useState('User');
    const [paymentConfirmed, setPaymentConfirmed] = useState('no');
    const [abstractApproved, setAbstractApproved] = useState('no');

    const [yesNoOptions] = useState([{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);
    const [sRoleOptions] = useState(coreContext.applicantCategories);

    const [presentingOptions] = useState([{ name: 'Select One', value: '' }, { name: 'Invited Talk', value: 'Invited Talk' }, { name: 'Contributed Talk', value: 'Contributed Talk' }, { name: 'Poster Presentation', value: 'Poster Presentation' },]);

    const [roleOptions] = useState([{ name: 'User', value: 'User' }, { name: 'Moderator', value: 'Moderator' }, { name: 'Admin', value: 'Admin' }]);


    //useEffect(setClient(coreContext.client), [client]);

    const fetchClient = () => {

        //this.props.match.params.id //accessing param
        // console.log('paramas', props.match.params.id);
        //console.log('paramas', props.location);
        coreContext.fetchClient(props.location, authContext.ownerId);
        coreContext.fetchJnv(props.match.params.id);
    }

    useEffect(fetchClient, []);


    const loadLoactionOptions = () => {
        coreContext.fetchCountries();
        if (coreContext.client.countryId)
            coreContext.fetchStates(coreContext.client.countryId);
        if (coreContext.client.stateId)
            coreContext.fetchDistricts(coreContext.client.countryId, coreContext.client.stateId);
        if (coreContext.client.id)
            coreContext.fetchLocalAreas(coreContext.client.id);
        else history.push('/applicants');
    }



    const populateClient = () => {
        //  console.log(coreContext.jnv);
        setName(coreContext.client.item_name);
        setContactName(coreContext.client.item_contact_person);
        setTag(coreContext.client.item_tag);
        setLOC(coreContext.client.item_loc);
        setEmail(coreContext.client.item_email);
        setErpBranchid(coreContext.client.erp_branchid);
        setIsCollege(coreContext.client.is_college);
        setContact1(coreContext.client.item_mobile_1);
        setContact2(coreContext.client.item_mobile_2);
        setContact(coreContext.client.item_contact);
        setAddress(coreContext.client.item_address);
        setDescription(coreContext.client.item_description);
        setPincode(coreContext.client.item_pincode);
        setLocalArea(coreContext.client.areaId);
        setStateId(coreContext.client.stateId);
        setDistrictId(coreContext.client.districtId);
        setCategory(coreContext.client.categoryId);
        setSCategory(coreContext.client.smst_category);
        setCountryId(coreContext.client.countryId);
        // if(coreContext.client.enroll_today_image)
        setVideos([coreContext.client.enroll_today_image]);
        // else window.location.href = '/applicant';
        //  if(coreContext.client.register_now_image)
        setImages([coreContext.client.register_now_image]);
        // else window.location.href = '/applicant';
        setStatus(coreContext.client.item_status);
        setSiteURL(coreContext.client.site_url);
        setHideMenu(coreContext.client.hide_menu);
        setHideFooter(coreContext.client.hide_footer);

        setNationality(coreContext.client.smst_nationality);
        //  fetchCategory();
        if (coreContext.jnv)
            setOrganization(coreContext.jnv.district + ' ' + coreContext.jnv.address);
        setDesignation(coreContext.client.smst_designation);
        setAccomodation(coreContext.client.smst_accomodation);
        setPresenting(coreContext.client.smst_presenting);
        setRole(coreContext.client.smst_designation);
        setPaymentConfirmed(coreContext.client.smst_booking_confirmed);
        setAbstractApproved(coreContext.client.smst_abstract_accepted);
        setJDate(coreContext.client.membership_start_date);

    }

    const fetchCategory = () => {
        const v = sRoleOptions.filter(obj => obj.value == coreContext.client.categoryId);
        if (v[0])
            setSCategory(v[0].name);
    }

    useEffect(loadLoactionOptions, [coreContext.client]);
    useEffect(populateClient, [coreContext.client]);

    const renderImages = () => {
        if (images.length > 0)
            return images.map(image => <a rel="noopener noreferrer" target="_blank" href={`http://${image}`}><img style={{ padding: 5 }} className="img-fluid" src={image.includes('.pdf') ? 'https://node-websites.siddhantait.com/images/pdfimage.png' : `http://${image}`} alt='Abstract not found' /></a>)
    }

    const renderVideos = () => {
        //console.log(coreContext.client);
        if (videos.length > 0)
            return videos.map(image => <a rel="noopener noreferrer" target="_blank" href={`http://${image}`}><img style={{ padding: 5 }} className="img-fluid" src={image.includes('.pdf') ? 'https://node-websites.siddhantait.com/images/pdfimage.png' : `http://${image}`} alt='Payment receipt not found' /></a>)
    }


    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    });


    const clientEditHandler = (data) => {
        coreContext.editClient(coreContext.client.id, authContext.ownerId, paymentConfirmed, abstractApproved, sCategory, role, jDate);

    }


    const onEmailChangedHandler = (e) => {
        setEmail(e.target.value);
    }

    const onContact1Handler = (e) => {
        setContact1(e.target.value);
    }


    const onNameChangedHandler = (e) => {
        setName(e.target.value);
    }



    return (<div className='container'>
        <div className="row" >
            <div className="col-md-6">
                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">Modify Applicant Details</Badge></h3></div>
                    <div className="card-body">
                        <Form autoComplete='off' onSubmit={handleSubmit(clientEditHandler)} noValidate>
                            <Input label='Name' readOnly={true} value={name} name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' onChange={onNameChangedHandler} />

                            <Input label='Email' name='email' value={email} required={true} register={register} errors={errors} elementType='text' readOnly={true} minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' onChange={onEmailChangedHandler} />

                            <div className="row" >
                                <div className="col-md-8">

                                    {/* <Input label='Category' readOnly={true} value={sCategory} name='category' minLength={1} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Category' onChange={(e) => setSCategory(e.target.value)} /> */}
                                    {authContext.item.smst_designation === 'Admin' ? <Input label='Role' name='role' value={role} required={true} register={register} errors={errors} elementType='select' options={roleOptions} onChange={(e) => setRole(e.target.value)} /> : ''}

                                    <Input label='Category' name='category' value={sCategory} required={true} register={register} errors={errors} elementType='select' options={sRoleOptions} onChange={(e) => setSCategory(e.target.value)} />

                                </div>
                                <div className="col-md-4"> <Link className="btn btn-link" to={`/reset-password/${coreContext.client.item_email}`}>Reset Password</Link>
                                    <Link className="btn btn-link" to={`/applicant-jnv-details/${coreContext.client.id}`}>{authContext.item.smst_designation === 'Admin' ? 'Full Profile' : ''}</Link>
                                    <Link className="btn btn-primary" to={`/list-for-sahyog/${coreContext.client.id}`}>List for Sahyog</Link></div>
                            </div>



                            <Input label='Navodaya Details' name='organization' readOnly={true} minLength={2} maxLength={255} required={true} value={organization} register={register} errors={errors} elementType='text' placeholder='Navodaya Details' onChange={(e) => setOrganization(e.target.value)} />



                            <Input label='Phone' name='contact1' value={contact} minLength={6} maxLength={15} required={true} readOnly={true} register={register} errors={errors} elementType='text' placeholder='Enter Phone no.' onChange={onContact1Handler} />


                            <Input label='Payment Confirmed' name='pconfirmed' value={paymentConfirmed} required={true} register={register} errors={errors} elementType='select' options={yesNoOptions} onChange={(e) => setPaymentConfirmed(e.target.value)} />

                            <Input label='Abstract Approved' name='aApproved' value={abstractApproved} required={true} register={register} errors={errors} elementType='select' options={yesNoOptions} onChange={(e) => setAbstractApproved(e.target.value)} />

                            <Input label='Joining Confirmation Date' value={jDate} name='jdate' minLength={10} maxLength={10} required={false} register={register} errors={errors} elementType='date' placeholder='Joining Date in current Jnv' onChange={(e) => setJDate(e.target.value)} />

                            <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                            <Input variant='danger' label={coreContext.message} elementType='label' />
                        </Form>
                    </div>
                </div>
            </div>
            <div className="col-md-6" style={{ maxHeight: '500px', overflowY: 'auto', position: 'sticky', top: '50px' }}>
                <div className="card">
                    <div className="card-header">Certificate</div>
                    <div className="card-body">
                        <div className="row">
                            {renderImages()}
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card">
                        <div className="card-header">Payment Receipt</div>
                        <div className="card-body">
                            <div className="row">
                                {renderVideos()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> </div>);
}


export { Client };