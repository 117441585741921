/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import PMenu from './common/ProfileMenu';
import ImageUploader from './common/UploadImage';


const Self = (props) => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [name, setName] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('');
    const [contact1, setContact1] = useState('');
    const [contact2, setContact2] = useState();
    const [contact, setContact] = useState();
    const [address, setAddress] = useState();
    const [paddress, setPaddress] = useState();
    const [bloodgroup, setBloodgroup] = useState();
    const [occupation, setOccupation] = useState();
    const [pincode, setPincode] = useState();
    const [localArea, setLocalArea] = useState('');
    const [stateId, setStateId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [category, setCategory] = useState(3);
    const [countryId, setCountryId] = useState('');
    const [image, setImage] = useState('');

    const [sRoleOptions] = useState(coreContext.applicantCategories);
    const [genderOptions] = useState([{ name: 'Select', value: '' }, { name: 'Male', value: 'Male' }, { name: 'Female', value: 'Female' }, { name: 'Others', value: 'Others' }]);


    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [iconUploadButtonDisabled, setIconUploadButtonDisabled] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);

    const [status, setStatus] = useState('');

    //useEffect(setClient(coreContext.client), [client]);

    const fetchClient = () => {

        //this.props.match.params.id //accessing param
        console.log('params', props.match);
        coreContext.fetchSelf(authContext.itemId);
    }

    useEffect(fetchClient, []);


    const loadLoactionOptions = () => {
        coreContext.fetchCountries();
        if (coreContext.client.countryId)
            coreContext.fetchStates(coreContext.client.countryId);
        if (coreContext.client.stateId)
            coreContext.fetchDistricts(coreContext.client.countryId, coreContext.client.stateId);
        if (coreContext.client.id)
            coreContext.fetchLocalAreas(coreContext.client.id);
    }



    const populateClient = () => {
        //   console.log(coreContext.client);
        setName(coreContext.client.item_name);
        setDob(coreContext.client.item_dob);
        setCategory(coreContext.client.smst_category);
        setGender(coreContext.client.item_gender);
        setImage(coreContext.client.profile_image);
        setBloodgroup(coreContext.client.item_blood_group);
        setOccupation(coreContext.client.item_occupation);
        setGender(coreContext.client.item_gender);
        setEmail(coreContext.client.item_email);
        setContact1(coreContext.client.item_mobile_1);
        setContact2(coreContext.client.item_mobile_2);
        setContact(coreContext.client.item_contact);
        setAddress(coreContext.client.item_address);
        setPaddress(coreContext.client.item_perm_address);
        setPincode(coreContext.client.item_pincode);
        setLocalArea(coreContext.client.areaId);
        setStateId(coreContext.client.stateId);
        setDistrictId(coreContext.client.districtId);
        setCountryId(coreContext.client.countryId);
        setStatus(coreContext.client.item_status);

    }

    useEffect(loadLoactionOptions, [coreContext.client]);
    useEffect(populateClient, [coreContext.client]);






    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    }
    );


    const clientEditHandler = (data) => {
        // console.log('here also');
        coreContext.editSelf(authContext.itemId, name, email, address, contact1, contact2, contact, pincode, localArea, stateId, districtId, category, countryId, status, dob, gender,bloodgroup, occupation, paddress);

    }




    const onPinChangedHandler = (e) => {
        setPincode(e.target.value);
    }

    const onAddressChangedHandler = (e) => {
        setAddress(e.target.value);
    }



    const onEmailChangedHandler = (e) => {
        setEmail(e.target.value);
    }

    const onContact1Handler = (e) => {
        setContact1(e.target.value);
    }


    const onContactHandler = (e) => {
        setContact(e.target.value);
    }

    const onContact2Handler = (e) => {
        setContact2(e.target.value);
    }


    const onCountryChangedHandler = (e) => {
        setCountryId(e.target.value);
        coreContext.fetchStates(e.target.value);
    }


    const onStateChangedHandler = (e) => {
        setStateId(e.target.value);
        coreContext.fetchDistricts(countryId, e.target.value);
    }

    const onDistrictChangedHandler = (e) => {
        setDistrictId(e.target.value);
    }

 const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

      const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        if (count > 1) { alert('Only one pdf should be uploaded for this section !'); return; }
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadProfileImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);

                }, 2000);

                setTimeout(() => {
                    history.push('/profile');
                }, 5000);
            };
        }
    }

    const renderImage = () => {
       
        if (image)
            return <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src={`https://${image}`} alt='Image' /></div>

            return <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src='https://node-websites.siddhantait.com/images/file-not-found-icon-png.png' alt='Image' /></div>
    }



    return (<div className='container'>
        <PMenu />
        <div className="row" >
            <div className="col-md-12">
                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">My Profile</Badge></h3></div>
                    <div className="card-body">
                        <Form autoComplete='off' onSubmit={handleSubmit(clientEditHandler)} noValidate>
                            <Input label='Name' value={name} name='name' minLength={5} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Name' onChange={e => setName(e.target.value)} />

                            <Input label='Date of Birth' value={dob} name='dob' minLength={10} maxLength={10} required={true} register={register} errors={errors} elementType='date' placeholder='Enter DOB' onChange={(e) => setDob(e.target.value)} />

                            <Input label='Gender' name='gender' value={gender} required={true} register={register} errors={errors} elementType='select' options={genderOptions} onChange={(e) => setGender(e.target.value)} />

                            <Input label='Email' name='email' value={email} required={true} register={register} errors={errors} elementType='text' readOnly={true} minLength={5} maxLength={55} pattern={/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/} placeholder='Enter Email' onChange={onEmailChangedHandler} />

                            <Input label='Category' name='category' value={category} required={true} register={register} errors={errors} elementType='select' options={sRoleOptions} onChange={(e) => setCategory(e.target.value)} />

                            <Input label='Blood Group' value={bloodgroup} name='bloodgroup' minLength={1} maxLength={10} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Blood Group' onChange={(e) => setBloodgroup(e.target.value)} />

                            <Input label='Contact' value={contact} name='contact' minLength={10} maxLength={10} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Contact No.' onChange={onContactHandler} />

                            <Input label='Mobile-I' name='contact1' value={contact1} minLength={10} maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 2.' onChange={onContact1Handler} />

                            <Input label='Mobile-II' value={contact2} name='contact2' minLength={10} maxLength={10} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Contact No 3.' onChange={onContact2Handler} />

                            <Input label='Occupation' value={occupation} name='occupation' minLength={2} maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Occupation' onChange={(e) => setOccupation(e.target.value)} />

                            <Input label='Local Address' value={address} name='address' minLength={5} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Current Address' onChange={onAddressChangedHandler} />

                            <Input label='Permanent Address' value={paddress} name='paddress' minLength={5} maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Permanent Address' onChange= {(e) => setPaddress(e.target.value)} />

                            <Input label='Pincode' value={pincode} name='pincode' minLength={6} maxLength={6} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Pincode' onChange={onPinChangedHandler} />

                            <Input label='Country' name='country' required={true} register={register} errors={errors} elementType='select' value={countryId} options={coreContext.countryOptions} onChange={onCountryChangedHandler} />

                            <Input label='State' name='state' required={true} register={register} errors={errors} elementType='select' value={stateId} options={coreContext.stateOptions} onChange={onStateChangedHandler} />

                            <Input label='District' name='district' required={true} register={register} errors={errors} elementType='select' value={districtId} options={coreContext.districtOptions} onChange={onDistrictChangedHandler} />
                            <br />

                            <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                            <Input variant='danger' label={coreContext.message} elementType='label' />
                        </Form>
                    </div>
                </div>

                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">Upload Your Image </Badge></h3></div>
                    <div className="card-title mx-auto"></div>
                    <div className="card-body">
                        <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='1920' pictures={selectedImages} />
                        <Input value='Upload Image' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                        <Input variant='danger' label={coreContext.message} elementType='label' />
                    </div>
                    <div className="card-body">
                        <div className="card-title mx-auto"><h3><Badge variant="success">My Image</Badge></h3></div>
                        <div className="row">
                            {renderImage()}
                        </div>
                    </div>
                </div>

            </div>

        </div> </div>);
}


export { Self };