/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Input from './common/Input';
import { Eye } from 'react-bootstrap-icons';
import { XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { Link } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const Clients = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    const [filter, setFilter] = useState('');
    const [category, setCategory] = useState('');
    const [organization, setOrganization] = useState('');

    const [filterOptions] = useState([{ name: 'All', value: '' }, { name: 'Fee Paid', value: 'fee-paid' }, { name: 'Fee Not Paid', value: 'fee-not-paid' }, { name: 'Payment Confirmed', value: 'fee-confirmed' }, { name: 'Payment Not Confirmed', value: 'fee-not-confirmed' }, { name: 'Certificate Submitted', value: 'certificate-submitted' }, { name: 'Certificate Not Submitted', value: 'certificate-not-submitted' }, { name: 'Certificate Approved', value: 'certificate-approved' }, { name: 'Certificate Not Approved', value: 'certificate-not-approved' }]);

    const [sRoleOptions] = useState(coreContext.applicantCategories);


    const fetchCategory = (id) => {
        const v = sRoleOptions.filter(obj => obj.value == id);
        if (v[0])
            return (v[0].name);
    }

    const fetchClients = () => {
        if (authContext.item.smst_designation === 'Admin' || authContext.item.smst_designation === 'Moderator')
            coreContext.fetchClients(authContext.ownerId);
    }

    useEffect(fetchClients, []);

    const changeFilter = (fil) => {
        setFilter(fil);

        let fClients = [];
        if (fil === '') fClients = coreContext.clients;
        else if (fil === 'fee-paid') {
            fClients = coreContext.clients.filter(cc => cc.smst_payment_done === 'yes');
        }
        else if (fil === 'fee-not-paid') {
            fClients = coreContext.clients.filter(cc => cc.smst_payment_done === 'no');
        }
        else if (fil === 'fee-confirmed') {
            fClients = coreContext.clients.filter(cc => cc.smst_booking_confirmed === 'yes');
        }
        else if (fil === 'fee-not-confirmed') {
            fClients = coreContext.clients.filter(cc => cc.smst_booking_confirmed === 'no');
        }
        else if (fil === 'certificate-submitted') {
            fClients = coreContext.clients.filter(cc => cc.smst_abstract_uploaded === 'yes');
        }
        else if (fil === 'certificate-not-submitted') {
            fClients = coreContext.clients.filter(cc => cc.smst_abstract_uploaded === 'no');
        }
        else if (fil === 'certificate-approved') {
            fClients = coreContext.clients.filter(cc => cc.smst_abstract_accepted === 'yes');
        }
        else if (fil === 'certificate-not-approved') {
            fClients = coreContext.clients.filter(cc => cc.smst_abstract_accepted === 'no');
        }

        coreContext.setFilteredClients(fClients);

    }

    const changeSRole = (fil) => {
        setCategory(fil);

        let fClients = [];
        if (fil === '') fClients = coreContext.clients;

        else
            fClients = coreContext.clients.filter(cc => cc.smst_category === fil);


        coreContext.setFilteredClients(fClients);

    }

    const changeOrganizationFilter = (fil) => {
        setOrganization(fil);
        let fClients = [];
        if (fil === '') fClients = coreContext.clients;

        fClients = coreContext.clients.filter(cc => cc.smst_organization.toLowerCase().includes(fil.toLowerCase()) || cc.item_name.toLowerCase().includes(fil.toLowerCase()));

        coreContext.setFilteredClients(fClients);

    }

    const showDeleteWarning = (cl) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('This applicant will be removed permanently, do you still want to delete ?')) {
            coreContext.deleteClient(cl, authContext.ownerId);
        }
    }


    const renderClients = () => {
        if (coreContext.filteredClients) {
            return coreContext.filteredClients.map((client, index) => {

                let style = {};

                if (client.smst_booking_confirmed !== 'yes' && client.smst_booking_confirmed !== 'yes')
                    style = { backgroundColor: '#8B0000', color: 'white' };

                else if (client.smst_booking_confirmed !== 'yes' || client.smst_booking_confirmed !== 'yes' ||  !client.membership_start_date)
                    style = { backgroundColor: '#CD5C5C', color: 'white' };
                else
                    style = { backgroundColor: 'white' };



                return <tr style={style} key={++index}>
                    <td>{index}</td>
                    <td>{client.item_name}</td>
                    <td>{client.item_email}</td>
                    <td>{client.smst_organization}</td>
                    <td>{fetchCategory(client.smst_category)}</td>
                    <td><Link className="btn btn-primary" to={`/applicant/${client.id}`} title="Manage Applicant"><Eye size="12" /></Link></td>
                    <td><button className="btn btn-danger" onClick={() => showDeleteWarning(client.id)} href='#' title="Delete Applicant"><XSquare size="12" /></button></td>
                </tr>
            });
        }
        else {
            return (<tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>)
        }
    }

    return (

        <div className="card">
            {/* <div className="card-title mx-auto"> */}
            <div className="card-title">
                <div className='row'>
                    <div className="col-md-2"> <h3>
                        <Badge variant="success">Applicant's List</Badge>
                    </h3></div>
                    <div className="col-md-2">
                        <Input label='Filter By' register={register} name='filter' elementType='select' options={filterOptions} onChange={(e) => changeFilter(e.target.value)} />
                    </div>
                    <div className="col-md-2">
                        <Input label='Filter By Category' register={register} name='category' elementType='select' options={sRoleOptions} onChange={(e) => changeSRole(e.target.value)} />
                    </div>
                    <div className="col-md-3">
                        <Input label='Filter by' name='organization' minLength={2} maxLength={55} register={register} errors={errors} elementType='text' placeholder='Candidate / Organization Name' onChange={(e) => changeOrganizationFilter(e.target.value)} />
                    </div>
                    <div className="col-md-2">
                        <ReactHTMLTableToExcel
                            id="applicant-table-xls-button"
                            className="btn btn-primary"
                            table="applicantTable"
                            filename="tablexls"
                            sheet="tablexls"
                            buttonText="Download List as XLS" />

                    </div>
                </div>

            </div>
            <div className="card-body table-responsive">
                <table className='table table-sm table-bordered' id='applicantTable'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Institute</th>
                            <th>Category</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderClients()}
                    </tbody>
                </table>
            </div>
        </div>

    );

}


export { Clients };