/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";


const ApplicantProfileMenu = (props) => {

  
    return (<div className='btn btn-group'>
    <Link className="btn btn-primary ml-2" to={`/applicant-jnv-details/${props.id}`}>JNV Details</Link>
    <Link className="btn btn-primary ml-2" to={`/applicant-jnv-health-details/${props.id}`}>Health Details</Link>
    <Link className="btn btn-primary ml-2" to={`/applicant-jnv-family-details/${props.id}`}>Family Details</Link>
        </div>);

}

export default ApplicantProfileMenu;
