/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import Axios from 'axios';
import Input from './common/Input';
import { useForm } from 'react-hook-form';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import ImageUploader from './common/UploadImage';
import { ToastContainer } from 'react-toastify';


const ListForSahyog = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    }
    );

    const [newPass, setNewPass] = useState('');
    const [itemEvents, setItemEvents] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [message, setMessage] = useState('');
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
  




    const raiseSahyogEvent = async () => {
        const count = selectedImages.length;
        if (count > 1) { alert('Only one pdf should be uploaded for this section !'); return; }
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', props.match.params.id);
            formData.append('comments', newPass);
            formData.append('ownerId', authContext.ownerId);
            formData.append('image', image, image.name);
    

            Axios.post('/update-sahyog-event', formData).then(response => {
                coreContext.setShowLoader(false);
                setNewPass('');
                const value = response.data.message;
                coreContext.setMessage(value);
                fetchClient();
            }).catch(err => {
              
                coreContext.setShowLoader(false);
                const value = err.response.data.data;
                coreContext.setMessage(value[0].msg);
                coreContext.setShowLoader(false);
            });

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);

                }, 2000);
            };
        }
    }


    // const raiseSahyogEvent = () => {

    //     Axios.post('/update-sahyog-event', { itemId:props.match.params.id, comments: newPass, ownerId: authContext.ownerId }).then(response => {
    //         coreContext.setShowLoader(false);
    //         setNewPass('');
    //         const value = response.data.message;
    //         coreContext.setMessage(value);
    //         fetchClient();
    //     }).catch(err => {
          
    //         coreContext.setShowLoader(false);
    //         const value = err.response.data.data;
    //         coreContext.setMessage(value[0].msg);
    //         coreContext.setShowLoader(false);
    //     });

    // }

    const changeStatus = (st, eventId) => {
        const newSt = st=== 'active' ? 'inactive': 'active';
        Axios.post('update-event-status', {status: newSt, eventId: eventId}).then(response => {
            fetchClient();
        });
    }


    const renderItemEvents = () => {
        if (itemEvents.length > 0) {
            return itemEvents.map((se, index) => {

                return <table className="table table-bordered"><tr key={++index}>
                    <th>{index}</th>
                    <td>{se.comments}</td>
                    <td>{new Date(se.createdAt).toDateString()}</td>
                    <td>{se.status}</td>
                    <td>{renderCertificate(se.imageUrl)}</td>
                    <td><button style={{ backgroundColor: '#bb2d3b'}} className="btn btn-danger" onClick={()=>changeStatus(se.status, se.id)}>Change Status</button></td>
                    <td> </td>
                </tr></table>
            });
        }
    }


    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        
        if (pictures.length === 1) { setUploadButtonDisabled(false); coreContext.setMessage('');} else { setUploadButtonDisabled(true); coreContext.setMessage('You can upload one image only !');  }
        
    }

    const fetchClient = () => {

        coreContext.setMessage('');

        Axios.get('/jnv-sahyog-events-item', { params: { ownerId: authContext.ownerId, itemId: props.match.params.id } }).then(response => {
            //  console.log(response.data);
            setItemEvents(response.data.events);
        }).catch(err => {
            

        });


        //this.props.match.params.id //accessing param
        // console.log('paramas', props.match.params.id);
        //console.log('paramas', props.location);
       // coreContext.fetchClient(props.location, authContext.ownerId);
       
    }


    useEffect(fetchClient, []);


    const onNewPasswordChange = (e) => {
        setNewPass(e.target.value);
    }

    const renderCertificate = (certificate) => {
       
        if (certificate){
            if(certificate.includes('.pdf'))
            return <div className="col-md-6"> <a rel="noopener noreferrer" target="_blank" href={`https://${certificate}`}><img style={{ padding: 5 }} className="img-fluid" src="https://node-websites.siddhantait.com/images/pdfimage.png" alt='Certificate' /></a></div>
            else
            return <div className="col-md-6"> <img style={{ padding: 5 }} className="img-fluid" src={`https://${certificate}`} alt='Certificate' /></div>
        }

            return <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src='https://node-websites.siddhantait.com/images/file-not-found-icon-png.png' alt='Certificate' /></div>
    }

  


    return (<div className='container-fluid'>
        <div className="card">
          
            <div className="card-title mx-auto"><h3><Badge variant="success">Raise Sahyog Event</Badge></h3></div>
            <div className="card-body">
            <Badge variant="danger">{coreContext.client.item_name}</Badge>
                <Form autoComplete='off' onSubmit={handleSubmit(raiseSahyogEvent)} noValidate>
                    <div className='row'>

                        <div className="col-md-3"> <Input  label='Enter Comment' name='newpass' value={newPass} minLength={2} maxLength={200} required={true} register={register} errors={errors} elementType='textarea' placeholder='Event Description' onChange={onNewPasswordChange} /></div>
                        <div className="col-md-3">
                        <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">Upload Supporting Document </Badge></h3></div>
                    <div className="card-title mx-auto"></div>
                    <div className="card-body">
                        <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='1920' pictures={selectedImages} />
                       
                    </div>
                  
                </div>
                </div>
                        </div>   <div className='row'>
                        <div className="col-md-3">  <h5><Badge variant='danger'></Badge></h5><Input disabled={uploadButtonDisabled} elementType='button'  variant='primary' value="Update" /></div>

                        <div className="col-md-3"> <Input variant='danger' label={coreContext.message} elementType='label' /></div>
                    </div>
                </Form>
            </div>
        <div className="card-body">
            {renderItemEvents()}
        </div>
        </div>
        <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={true}
            type='success'
        />
    </div>
    )
}

export { ListForSahyog }