/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import { slide as BMenu } from 'react-burger-menu';
import { AuthContext } from '../../context/auth-context';
import { CoreContext } from '../../context/core-context';
import headerImage from '../../assets/images/navodaya_banner.jpg';
const Menu = (props) => {

    const authContext = useContext(AuthContext);
    const coreContext = useContext(CoreContext);


    const [isOpen, setIsOpen] = useState(false);

    const handleOnOpen = () => {
        setIsOpen(!isOpen);
    };

    const loginLink = '/login/'+ coreContext.ownerId;

    const showAdminButton = () =>{
        if(authContext.item.smst_designation === 'Admin' || authContext.item.smst_designation === 'Moderator')
        return <Link style={{position: 'absolute', top: '5px', left:'150px'}} className="btn btn-danger" to='/applicants'>Admin</Link>; else return null;
    }

    const showSettingsButton = () =>{
        if (authContext.isAuth && authContext.itemId)
        return <Link style={{position: 'absolute', top: '5px', right:'150px'}} className="btn btn-danger" to='/settings'>Settings</Link>; else return null;
    }

    const showLogButton = () => {
        if (authContext.isAuth && authContext.itemId)
        return    <Link style={{position: 'absolute', top: '5px', right:'10px'}} className="btn btn-danger" to="/logout">Sign Out</Link>; else {
        //  console.log('prakash1', coreContext.pathName);
            if(coreContext.pathName === '/login' || coreContext.pathName === '/'  || coreContext.pathName === '/'+authContext.ownerId)
         return <Link style={{position: 'absolute', top: '5px', right:'10px'}} className="btn btn-danger" to="/add-user">Register Now</Link>
         else
         return <Link style={{position: 'absolute', top: '5px', right:'10px'}} className="btn btn-danger" to='/'>Sign In</Link>
        }
    }

    return (<div><center><img src={headerImage} alt='ambt-2023' width='800px' /></center>
<Link style={{position: 'absolute', top: '5px', left:'10px'}} className="btn btn-danger" to='/'>Home</Link>
{showAdminButton()}

 {showLogButton()}
 {showSettingsButton()}
    </div>);

}

export default Menu;
