/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import Axios from 'axios';
import { Eye } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import PMenu from './common/ProfileMenu';
import { Link } from 'react-router-dom';



const JnvFamily = (props) => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [name, setName] = useState('');
    const [relation, setRelation] = useState('');
    const [id, setId] = useState('');
    const [dependent, setDependent] = useState('');
    const [nominee, setNominee] = useState('');
    const [dob, setDob] = useState();
    const [buttonText, setButtonText] = useState('Add');
    const [familyOptions] = useState(coreContext.familyOptions);
    const [yesNoOptions] = useState([{ name: 'Select', value: '' },{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);



    const fetchJnvFamily = () => {

        //this.props.match.params.id //accessing param
       // console.log('params', props.match);
        coreContext.fetchJnvFamily(authContext.itemId);
    }

    const editMember = (m) => {
        setId(m.id);
        setRelation(m.relation);
        setName(m.rel_name);
        setDob(m.rel_dob);
        setDependent(m.rel_dependent);
        setNominee(m.rel_nominee);
    }
 
    useEffect(fetchJnvFamily, []);

    const showDeleteWarning = (cl) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm('This member will be removed permanently, do you still want to delete ?')){
           deleteMember(cl, authContext.itemId);
        }
    }

    const deleteMember = (id, itemId) => {
        Axios.post('/delete-member', { id, itemId}).then((response) => {
            // window.location.assign('/events');
            fetchJnvFamily(itemId);

        }).catch(err => { });
    }

    const renderMembers = () => {
        if (coreContext.jnvFamilyMembers) {
            return coreContext.jnvFamilyMembers.map((client, index) => (
                <tr key={++index}>
                    <td>{index}</td>
                    <td>{client.relation}</td>
                    <td>{client.rel_name}</td>
                    <td>{client.rel_dob}</td>
                    <td>{client.rel_dependent}</td>
                    <td>{client.rel_nominee}</td>
                    <td> <a className="btn btn-primary" onClick={()=> editMember(client)} href="#"><Eye size="16" /></a></td>
                    <td><button className="btn btn-danger" onClick={()=>showDeleteWarning(client.id)} href='#' title="Delete Applicant"><XSquare size="12" /></button></td>
                </tr>
            ));
        }
        else {
            return (<tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>)
        }
    }






    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    }
    );



    const jnvUpdateHandler = (data) => {
        // console.log('here also');

        coreContext.setShowLoader(true);
        Axios.post('/update-jnv-family', { itemId: authContext.itemId, id, relation, name, dob, dependent, nominee }).then(response => {
            coreContext.setShowLoader(false);
            const value = response.data.message;
            coreContext.setMessage(value);
            fetchJnvFamily();
            setId('');
            setRelation('');
            setName('');
            setDob('');
            setDependent('');
            setNominee('');
        }).catch(err => {
            coreContext.setShowLoader(false);
            const value = err.response.data.data;
            coreContext.setMessage(value[0].msg);
            coreContext.setShowLoader(false);
        });

    }





    return (<div className='container'>
        <PMenu />
        <div className="row" >
            <div className="col-md-12">
                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">Add Family Details</Badge></h3></div>
                    <div className="card-body">
                        <Form autoComplete='off' onSubmit={handleSubmit(jnvUpdateHandler)} noValidate>

                        <Input label='Relationship' name='relation' required={true} register={register} errors={errors} value={relation} elementType='select' options={familyOptions} onChange={(e) => setRelation(e.target.value)} />

                            <Input label='Family Member Name' value={name} name='name'  maxLength={55} required={true} register={register} errors={errors} elementType='text' placeholder='Enter family member name' onChange={e => setName(e.target.value)} />

                            <Input label='Family Member DOB' value={dob} name='dob' maxLength={10} required={true} register={register} errors={errors} elementType='date' placeholder='Enter Family Member DOB' onChange={(e) => setDob(e.target.value)} />

                            
                        <Input label='Is Family Member Financially Dependent on You' name='dependent' required={true} register={register} errors={errors} value={dependent} elementType='select' options={yesNoOptions} onChange={(e) => setDependent(e.target.value)} />

                          <Input label='Do you want to make this family member nominee in this account' name='nominee' required={true} register={register} errors={errors} value={nominee} elementType='select' options={yesNoOptions} onChange={(e) => setNominee(e.target.value)} />
                       
                            <br />

                            <Input elementType='button' variant='primary' value={buttonText} /> &nbsp;&nbsp; {coreContext.renderLoader()}
                            <Input variant='danger' label={coreContext.message} elementType='label' />
                        </Form>
                    </div>
                </div>

            </div>

        </div>
        <h4 className='mt-4'>Family Members</h4>
        <div className="card-body table-responsive">
                <table className='table table-sm table-bordered' id='applicantTable'>
                  
                    <thead>
                        <tr>
                            <th></th>
                            <th>Relation</th>
                            <th>Name</th>
                            <th>DOB</th>
                            <th>Dependent</th>
                            <th>Nominee</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderMembers()}
                    </tbody>
                </table>
            </div>
         </div>);
}


export { JnvFamily };