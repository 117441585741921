import React, { useContext } from 'react';
import * as Pages from './components';
import Menu from './components/common/Menu';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthContext } from './context/auth-context';
import Axios from 'axios';


function App(props) {

  const authContext = useContext(AuthContext);

  Axios.defaults.headers.common.AUTHORIZATION = 'Bearer ' + authContext.jwt;

  //let content = <Pages.Login />

  let content =
    <div className='container-fluid'>
      <Router>
      <Menu />
        <div style={{ height: '20px' }}></div>
        <Switch>
          <Route exact path='/join' component={Pages.Join} />
          <Route exact path='/add-user/:website' component={Pages.AddUser} />
          <Route exact path='/add-user' component={Pages.AddUser} />
          <Route exact path='/search' component={Pages.CoachingList} />
          <Route exact path='/coaching-details/:id' component={Pages.ItemDetails} />
          <Route path='/:website' component={Pages.Login} />
          <Route path='/' component={Pages.Login} />
        </Switch>
      </Router>
    </div>
  if (authContext.isAuth && authContext.itemId) content = (
    <div className='container-fluid'>
      <Router>
        <Menu />
        <div style={{ height: '20px' }}></div>
        <Switch>
          <Route exact path='/' component={Pages.CoachingListAdmin} />
          <Route exact path='/sign-up' component={Pages.SignUp} />
          <Route exact path='/logout' component={Pages.LogOut} />
          <Route exact path='/categories' component={Pages.Category} />
          <Route exact path='/features' component={Pages.Feature} />
          <Route exact path='/roles' component={Pages.Role} />
          <Route exact path='/applicants' component={Pages.Clients} />
          <Route exact path='/applicant/:id' component={Pages.Client} />
          <Route exact path='/profile/' component={Pages.Self} />
          <Route exact path='/jnv-details' component={Pages.Jnv} />
          <Route exact path='/applicant-jnv-details/:id' component={Pages.JnvClient} />
          <Route exact path='/jnv-family-details' component={Pages.JnvFamily} />
          <Route exact path='/applicant-jnv-family-details/:id' component={Pages.JnvFamilyClient} />
          <Route exact path='/jnv-health-details' component={Pages.JnvHealth} />
          <Route exact path='/applicant-jnv-health-details/:id' component={Pages.JnvHealthClient} />
          <Route exact path='/settings' component={Pages.Settings} />
          <Route exact path='/reset-password/:email' component={Pages.ResetPasswordAdmin} />
          <Route exact path='/list-for-sahyog/:id' component={Pages.ListForSahyog} />
          <Route exact path='/contribute-in-sahyog/:id' component={Pages.ContributeInSahyog} />
          <Route exact path='/sahyog-events' component={Pages.SahyogEvents} />
          <Route exact path='/sahyog-event-contributors/:id/:name' component={Pages.SahyogEventContributors} />
          <Route exact path='/page/:id' component={Pages.SinglePage} />
          <Route exact path='/blog/:id' component={Pages.SingleBlog} />
          <Route exact path='/users' component={Pages.Users} />
          <Route exact path='/enroll-today' component={Pages.EnrollToday} />
          <Route exact path='/upload-receipt' component={Pages.UploadReceipt} />
          <Route exact path='/upload-abstract' component={Pages.UploadAbstractDocs} />
          <Route path='/:website' component={Pages.CoachingListAdmin} />
          <Route exact path='/local-areas' component={Pages.LocalAreas} />
          <Route exact path='/countries' component={Pages.Countries} />
          <Route exact path='/states' component={Pages.States} />
          <Route exact path='/districts' component={Pages.Districts} />
          <Route exact path='/self' component={Pages.Self} />
          <Route exact path='/sliders' component={Pages.Slider} />
          <Route exact path='/educational-solutions' component={Pages.EducationSolution} />
          <Route exact path='/register-now' component={Pages.RegisterNow} />
          <Route exact path='/teachers' component={Pages.Teacher} />
          <Route exact path='/testimonials' component={Pages.Testimonial} />
          <Route exact path='/events' component={Pages.UpcomingEvents} />
          <Route exact path='/generals' component={Pages.Generals} />
          <Route exact path='/event/:id' component={Pages.SingleEvent} />
          <Route exact path='/general/:id' component={Pages.SingleGeneral} />
          <Route exact path='/password' component={Pages.ChangePassword} />
          <Route exact path='/add-event' component={Pages.AddEvent} />
          <Route exact path='/add-general' component={Pages.AddGeneral} />
          <Route exact path='/add-page' component={Pages.AddPage} />
          <Route exact path='/add-blog' component={Pages.AddBlog} />
          <Route exact path='/pages' component={Pages.Pages} />
          <Route exact path='/blogs' component={Pages.Blogs} />
          <Route exact path='/fun-facts' component={Pages.FunFacts} />
          <Route exact path='/images' component={Pages.Images} />
          <Route exact path='/videos' component={Pages.YouTubeVideos} />
        
          <Route component={Pages.NotFound} />
        </Switch>
      </Router>
    </div>
  )

  return (
    content
  );
}

export default App;
