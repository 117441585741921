/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import { useForm } from 'react-hook-form';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';

import { ToastContainer } from 'react-toastify';


const ResetPasswordAdmin = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    }
    );

    const [cuPass, setCuPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [conPass, setConPass] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [message, setMessage] = useState('');




    const fetchClient = () => {
        coreContext.fetchSelf(authContext.itemId);
    }

    const changePasswordHandler = () => {
        authContext.resetPassword(props.match.params.email,  newPass);
    }


    useEffect(fetchClient, []);


    const onNewPasswordChange = (e) => {
        setNewPass(e.target.value);
        onCheckNewPassword(e.target.value, conPass);
    }

    const onConfirmPasswordChange = (e) => {
        setConPass(e.target.value);
        onCheckNewPassword(newPass, e.target.value);
    }

    const onCheckNewPassword = (np, cp) => {

        if (np !== cp) {
            setPasswordMatch(false);
            setMessage('Passwords do not match !');
        } else {
            setPasswordMatch(true);
            setMessage('');
        }
    }


    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Reset Password</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(changePasswordHandler)} noValidate>
                    <div className='row'>

                        <div className="col-md-3"> <Input  label='Enter New Password' name='newpass' value={newPass} minLength={2} maxLength={55} required={true} register={register} errors={errors} elementType='password' placeholder='Enter New Password' onChange={onNewPasswordChange} /></div>
                        <div className="col-md-3"> <Input  label='Confirm New Password' name='conpass' value={conPass} minLength={2} maxLength={55} required={true} register={register} errors={errors} elementType='password' placeholder='Confirm New Password' onChange={onConfirmPasswordChange} />
                           
                        </div>
                     
                        </div>   <div className='row'>
                        <div className="col-md-3">  <h5><Badge variant='danger'></Badge></h5><Input elementType='button' disabled={ !passwordMatch} variant='primary' value="Update" /></div>

                        <div className="col-md-3"> <Input variant='danger' label={message} elementType='label' /></div>
                    </div>
                </Form>
            </div>
        </div>
        <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={true}
            type='success'
        />
    </div>
    )
}

export { ResetPasswordAdmin }