/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import Axios from 'axios';
import Input from './common/Input';
import { useForm } from 'react-hook-form';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ImageUploader from './common/UploadImage';

const ContributeInSahyog = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    }
    );

    const [newPass, setNewPass] = useState('');
    const [amount, setAmount] = useState('');
    const [clientName, setClientName] = useState('');
    const [status, setStatus] = useState('not-verified');
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

    const [certificate, setCertificate] = useState('');


    const fetchClient = () => {

        //this.props.match.params.id //accessing param
        // console.log('paramas', props.match.params.id);
        //console.log('paramas', props.location);
       // coreContext.fetchClient(props.location, authContext.ownerId);

Axios.get('jnv-event-items', {params: {eventId: props.match.params.id, itemId: authContext.itemId}}).then(response=>{
console.log(response.data.event);
if(response.data.event){
    setCertificate(response.data.event.paymentUrl);
    setAmount(response.data.event.amount);
    setNewPass(response.data.event.comments);
    setStatus(response.data.event.status);
    setClientName(response.data.event.item_name);
    if(response.data.event.status === 'verified'){ setUploadButtonDisabled(true);   coreContext.setMessage('Your contribution has been verified !');}
}
});

    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        if (count > 1) { alert('Only one pdf should be uploaded for this section !'); return; }
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('eventId', props.match.params.id);
            formData.append('image', image, image.name);
            formData.append('itemId', authContext.itemId);
            formData.append('amount', amount);
            formData.append('comment', newPass);

            Axios.post('/update-event-item', formData).then(response => {
                coreContext.setShowLoader(false);
                setNewPass('');
                setAmount('');
                const value = response.data.message;
                coreContext.setMessage(value);
            }).catch(err => {
              
                coreContext.setShowLoader(false);
                const value = err.response.data.data;
                coreContext.setMessage(value[0].msg);
                coreContext.setShowLoader(false);
            });

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);

                }, 2000);

                setTimeout(() => {
                    history.push('/');
                }, 5000);
            };
        }
    }


    useEffect(fetchClient, []);


    const onNewPasswordChange = (e) => {
        setNewPass(e.target.value);
    }

    const changeAmount = (e) => {
        setAmount(e.target.value);
    }


    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if(status !== 'verified'){
        if (pictures.length === 1) { setUploadButtonDisabled(false); coreContext.setMessage('');} else { setUploadButtonDisabled(true); coreContext.setMessage('You can upload one image only !');  }
        } else {
            setUploadButtonDisabled(true);
            coreContext.setMessage('Already verfied by the admin !');
        }
    }
  
    const renderCertificate = () => {
       
        if (certificate)
            return <div className="col-md-6"> <img style={{ padding: 5 }} className="img-fluid" src={`https://${certificate}`} alt='Certificate' /></div>

            return <div className="col-md-6"><img style={{ padding: 5 }} className="img-fluid" src='https://node-websites.siddhantait.com/images/file-not-found-icon-png.png' alt='Certificate' /></div>
    }


    return (<div className='container-fluid'>
        <div className="card">
          
            <div className="card-title mx-auto"><h3><Badge variant="success">Contribute In Sahyog Event</Badge></h3></div>
            <div className="card-body">
            <Badge variant="danger">{clientName}</Badge>
                <Form autoComplete='off' onSubmit={handleSubmit(onImagesUploadHandler)} noValidate>
                    <div className='row'>

                    <div className="col-md-3"> <Input readOnly={status === 'verified'}  label='Enter Amount (Rs.)' name='amount' value={amount} minLength={1} maxLength={6} required={true} register={register} errors={errors} elementType='number' placeholder='Enter Amount' onChange={changeAmount} /></div>

                        <div className="col-md-3">
                             <Input  label='Enter Comment' readOnly={status === 'verified'} name='newpass' value={newPass} minLength={2} maxLength={200} required={true} register={register} errors={errors} elementType='textarea' placeholder='Event Description' onChange={onNewPasswordChange} /></div>
                        <div className="col-md-3">


                        <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">Upload Payment Slip </Badge></h3></div>
                    <div className="card-title mx-auto"></div>
                    <div className="card-body">
                        <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='1920' pictures={selectedImages} />
                       
                    </div>
                    <div className="card-body">
                        <div className="card-title mx-auto"><h3><Badge variant="success">Payment Slip</Badge></h3></div>
                        <div className="row">
                            {renderCertificate()}
                        </div>
                    </div>
                </div>

                        </div>
                      
                        <div className="col-md-3">  <h5><Badge variant='danger'></Badge></h5> <Input value='Submit' disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                     <br/>
                        <Input variant='danger' label={coreContext.message} elementType='label' />
                        </div>
                        </div>  
                </Form>
            </div>
        
        </div>
        <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={true}
            type='success'
        />
    </div>
    )
}

export { ContributeInSahyog }