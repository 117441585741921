/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import PMenu from './common/ProfileMenu';



const JnvHealth = (props) => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [name, setName] = useState('');
    const [details, setDetails] = useState();
    const [ayear, setAyear] = useState('');




    //useEffect(setClient(coreContext.client), [client]);

    const fetchJnv = () => {

        //this.props.match.params.id //accessing param
       // console.log('params', props.match);
        coreContext.fetchJnv(authContext.itemId);
    }

    useEffect(fetchJnv, []);





    const populateJnv = () => {
        //  console.log(coreContext.jnv);
        
        setName(coreContext.jnv.disease_name);
        setDetails(coreContext.jnv.disease_details);
        setAyear(coreContext.jnv.disease_year);
    }

    useEffect(populateJnv, [coreContext.jnv]);


    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    }
    );


    const jnvUpdateHandler = (data) => {
        // console.log('here also');
        coreContext.updateJnvHealth(authContext.itemId, name, details, ayear );

    }





    return (<div className='container'>
        <PMenu />
        <div className="row" >
            <div className="col-md-12">
                <div className="card">
                    <div className="card-title mx-auto"><h3><Badge variant="success">JNV Details</Badge></h3></div>
                    <div className="card-body">
                        <Form autoComplete='off' onSubmit={handleSubmit(jnvUpdateHandler)} noValidate>
                            <Input label='Disease Name (If any)' value={name} name='name'  maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter disease name' onChange={e => setName(e.target.value)} />

                            <Input label='Disease Details' value={details} name='details' maxLength={155} required={false} register={register} errors={errors} elementType='textarea' placeholder='Enter Disease Details' onChange={(e) => setDetails(e.target.value)} />

                            <Input label='Disease Years' value={ayear} name='ayear' minLength={4} maxLength={55} required={false} register={register} errors={errors} elementType='text' placeholder='Enter Disease Year eg. 2014-2015' onChange={(e) => setAyear(e.target.value)} />
                       
                            <br />

                            <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                            <Input variant='danger' label={coreContext.message} elementType='label' />
                        </Form>
                    </div>
                </div>

            </div>

        </div> </div>);
}


export { JnvHealth };