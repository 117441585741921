import React, { useState, useEffect } from "react";
import ImageUploader from "react-images-upload";
import imageCompression from 'browser-image-compression';

const maxSizeMB = 1;

const UploadImage = props => {
    const [pictures, setPictures] = useState([]);
    const [showPreview, setShowPreview] = useState(true);

    const resetSelectedImages = () => {
       // console.log('pimages', props.pictures);
        setPictures(props.pictures);
        if (props.pictures.length === 0)
                setShowPreview(false);
    }

    useEffect(resetSelectedImages, [props.pictures.length]);


    const handleImageUpload = async (imageFile) => {

        // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

        const options = {
            maxSizeMB: maxSizeMB,
            maxWidthOrHeight: props.maxWidthOrHeight ?? 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            //  console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            return compressedFile;
        } catch (error) {
            console.log(error);
            return null;
        }

    }




    const onDrop = async picture => {
      //  console.log(picture[0]);

        const newPictures = [];

        for (const pic of picture) {

            let file = pic.type === 'application/pdf' ? pic : await handleImageUpload(pic);
       //     console.log('resized', file);
            newPictures.push(file);
        }

        setShowPreview(true);
        setPictures([...pictures, newPictures]);

        props.onImagesSelected(newPictures);
    };
    return (
        <ImageUploader

            {...props}
            withIcon={true}
            withPreview={showPreview}
            withLabel={true}
            buttonText='Choose File'
            onChange={onDrop}
            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg', '.pdf']}
            maxFileSize={26214400}  //25mb
            // label='Max file size: 512kb, Accepted: jpg, png, gif, jpeg'
            label='Accepted: jpg, png, gif, jpeg'
        />
    );
};

export default UploadImage;