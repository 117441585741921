/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import Axios from 'axios';
import { Eye } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { XSquare } from 'react-bootstrap-icons';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import PMenu from './common/ApplicantProfileMenu';
import { Link } from 'react-router-dom';



const JnvFamilyClient = (props) => {
    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [name, setName] = useState('');
    const [relation, setRelation] = useState('');
    const [id, setId] = useState('');
    const [dependent, setDependent] = useState('');
    const [nominee, setNominee] = useState('');
    const [dob, setDob] = useState();
    const [buttonText, setButtonText] = useState('Add');
    const [familyOptions] = useState(coreContext.familyOptions);
    const [yesNoOptions] = useState([{ name: 'Select', value: '' },{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]);



    const fetchJnvFamily = () => {

        //this.props.match.params.id //accessing param
       // console.log('params', props.match);
       if (authContext.item.smst_designation === 'Admin')
        coreContext.fetchJnvFamily(props.match.params.id);
    }

    const editMember = (m) => {
        setId(m.id);
        setRelation(m.relation);
        setName(m.rel_name);
        setDob(m.rel_dob);
        setDependent(m.rel_dependent);
        setNominee(m.rel_nominee);
    }
 
    useEffect(fetchJnvFamily, []);

    const showDeleteWarning = (cl) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm('This member will be removed permanently, do you still want to delete ?')){
           deleteMember(cl, authContext.itemId);
        }
    }

    const deleteMember = (id, itemId) => {
        Axios.post('/delete-member', { id, itemId}).then((response) => {
            // window.location.assign('/events');
            fetchJnvFamily(itemId);

        }).catch(err => { });
    }

    const renderMembers = () => {
        if (coreContext.jnvFamilyMembers) {
            return coreContext.jnvFamilyMembers.map((client, index) => (
                <tr key={++index}>
                    <td>{index}</td>
                    <td>{client.relation}</td>
                    <td>{client.rel_name}</td>
                    <td>{client.rel_dob}</td>
                    <td>{client.rel_dependent}</td>
                    <td>{client.rel_nominee}</td>
                    <td> </td>
                </tr>
            ));
        }
        else {
            return (<tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>)
        }
    }






    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    }
    );



    const jnvUpdateHandler = (data) => {
        // console.log('here also');

        coreContext.setShowLoader(true);
        Axios.post('/update-jnv-family', { itemId: authContext.itemId, id, relation, name, dob, dependent, nominee }).then(response => {
            coreContext.setShowLoader(false);
            const value = response.data.message;
            coreContext.setMessage(value);
            fetchJnvFamily();
            setId('');
            setRelation('');
            setName('');
            setDob('');
            setDependent('');
            setNominee('');
        }).catch(err => {
            coreContext.setShowLoader(false);
            const value = err.response.data.data;
            coreContext.setMessage(value[0].msg);
            coreContext.setShowLoader(false);
        });

    }





    return (<div className='container'>
        <PMenu id={props.match.params.id} />
        <div className="row" >
            <div className="col-md-12">
                

            </div>

        </div>
        <h4 className='mt-4'>Family Members</h4>
        <div className="card-body table-responsive">
                <table className='table table-sm table-bordered' id='applicantTable'>
                  
                    <thead>
                        <tr>
                            <th></th>
                            <th>Relation</th>
                            <th>Name</th>
                            <th>DOB</th>
                            <th>Dependent</th>
                            <th>Nominee</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderMembers()}
                    </tbody>
                </table>
            </div>
         </div>);
}


export { JnvFamilyClient };